import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore tsc seems to bug out and misreport this line
import styles from './ArchonFooter.module.scss';
import ArchonTitle from '../ArchonTitle/ArchonTitle';
import Link from 'next/link';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import Body from '../../../resources/js/components/Body/Body';
import Heading from '../../../resources/js/components/Heading/Heading';
import TinyArticlePreview from '../../../resources/js/components/TinyArticlePreview/TinyArticlePreview';
import convertImageUrlToOptimizedImageUrl from '../../helpers/convertImageUrlToOptimizedImageUrl';
import VerticalContent from '../../../resources/js/components/VerticalContent/VerticalContent';

export type ArchonFooterViewModel = {
  contactUsLabel: string;
  forumsLabel: string;
  forumsUrl: string;
  subscribeLabel: string;
  subscribeUrl: string;
  latestArticleLabel: string;
  latestArticle: {
    title: string;
    url: string;
    timestamp: number;
    thumbnailUrl: string;
  };
  supportedGamesLabel: string;
  supportedGames: Array<{
    name: string;
    url: string;
    iconUrl: string;
  }>;
};

export default function ArchonFooter({
  contactUsLabel,
  forumsLabel,
  forumsUrl,
  subscribeLabel,
  subscribeUrl,
  latestArticleLabel,
  latestArticle,
  supportedGamesLabel,
  supportedGames,
}: ArchonFooterViewModel): JSX.Element {
  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.leftSide}>
          <div className={styles.titleAndMiscLinks}>
            <ArchonTitle fontSize={48} includeMargin={false} />
            <Body className={styles.miscLinks}>
              <Link href={'/contact'}>{contactUsLabel}</Link>
              {' - '}
              <a href={forumsUrl} target={'_blank'} rel={'nofollow noreferrer'}>
                {forumsLabel}
              </a>
              {' - '}
              <a
                href={subscribeUrl}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                {subscribeLabel}
              </a>
            </Body>
          </div>
          <VerticalContent gap={'tiny'}>
            <Body>
              <Link
                href={latestArticle.url}
                className={styles.latestArticleLink}
              >
                {latestArticleLabel}
              </Link>
            </Body>
            <Link href={latestArticle.url}>
              <TinyArticlePreview
                title={latestArticle.title}
                timestamp={latestArticle.timestamp}
                thumbnailUrl={convertImageUrlToOptimizedImageUrl(
                  latestArticle.thumbnailUrl,
                  128,
                  75
                )}
              />
            </Link>
          </VerticalContent>
        </div>
        <div className={styles.rightSide}>
          <Heading
            level={2}
            styleLevel={6}
            className={styles.supportedGamesTitle}
          >
            {supportedGamesLabel}
          </Heading>
          <Body className={styles.gameLinks}>
            {supportedGames.map((game) => (
              <Link href={game.url} key={game.name} className={styles.gameLink}>
                {game.name}{' '}
                <OptimizedImage
                  src={game.iconUrl}
                  alt={' '}
                  width={21}
                  height={21}
                  isZoomable={false}
                  includeMargin={false}
                />
              </Link>
            ))}
          </Body>
        </div>
      </div>
      <div className={styles.bottom}>
        &copy; 2013 - {new Date().getFullYear()} Archon
      </div>
    </footer>
  );
}

declare global {
  interface Window {
    locale: string;
  }
}

function fromNow(date: Date): string {
  const seconds = Math.ceil((new Date().getTime() - date.getTime()) / 1000);
  const years = Math.round(seconds / 31536000);
  const months = Math.round(seconds / 2592000);
  const days = Math.round(seconds / 86400);

  const relativeTimeFormatter = new Intl.RelativeTimeFormat(
    globalThis.window?.locale ?? 'en'
  );

  if (days >= 320) {
    return relativeTimeFormatter.format(-years, 'year');
  }
  if (days >= 26 && days <= 319) {
    return relativeTimeFormatter.format(-months, 'month');
  }

  const hours = Math.floor(seconds / 3600);

  if (hours >= 22 && days <= 25) {
    return relativeTimeFormatter.format(-days, 'day');
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes >= 45 && hours <= 21) {
    return relativeTimeFormatter.format(-hours, 'hour');
  }
  if (seconds >= 45 && minutes <= 44) {
    return relativeTimeFormatter.format(-minutes, 'minute');
  }

  return relativeTimeFormatter.format(-seconds, 'second');
}

export default fromNow;

import React from 'react';
import { AdPlacementFallbackViewModel } from '@resources/js/typings/viewModels/adPlacements';

export type Props = AdPlacementFallbackViewModel & {
  className?: string;
};

export const AdPlacementFallback = ({
  adPlacementFallbackType,
  siteTitle,
  headerImageUrl,
  backgroundImageUrl,
  href,
}: Props): JSX.Element => {
  return (
    <div className={`ad-placement-fallback`}>
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={`ad-placement-fallback__layout ad-placement-fallback__layout--${adPlacementFallbackType}`}
        style={{ backgroundImage: 'url(' + backgroundImageUrl + ')' }}
      >
        <img
          src={headerImageUrl}
          alt={siteTitle + ' Logo'}
          className={'ad-placement-fallback__site-logo'}
        />
        <div className={'ad-placement-fallback__content'}>
          <div>
            <span className={'ad-placement-fallback__greeting'}>
              HEY THERE.
            </span>
            <div className={'ad-placement-fallback__remain-free'}>
              We all want {siteTitle} to remain <b>free</b>.
            </div>
          </div>
          <div className={'ad-placement-fallback__work-hard'}>
            We work hard to offer you the best possible experience.
          </div>
          <div>
            Help us by{' '}
            <span className={'ad-placement-fallback__linkish'}>
              disabling your Adblock
            </span>{' '}
            or supporting us on{' '}
            <span className={'ad-placement-fallback__linkish'}>Patreon</span>.
          </div>
          <div className={'ad-placement-fallback__thank-you'}>
            Thank you for your help and support!
          </div>
          <div className={'ad-placement-fallback__view-more'}>
            <span className={'ad-placement-fallback__linkish'}>
              View All Subscriber Perks
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};
